var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"orders-list-view"}},[_c('material-card',{attrs:{"color":"primary","icon":"mdi-cart"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Open Orders ")]},proxy:true}])},[_c('v-data-table',{staticClass:"mr-2 ml-2",attrs:{"headers":_vm.headers,"items":_vm.carts,"items-per-page":15,"loading":_vm.loadingCarts,"loading-text":"Loading Open Orders... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mr-5",attrs:{"color":"primary","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.addCart($event)}}},[_vm._v(" New Cart ")])],1)]},proxy:true},{key:"item.customers",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customers.length)+" remaining ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openCart(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-cart-arrow-up ")])]}}],null,true)},[_c('span',[_vm._v("Open cart")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteCart(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete cart")])])]}},{key:"no-data",fn:function(){return [_vm._v(" No open orders found. ")]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }